import React from "react"
import { Benefit, Main, Row, Text } from "../../../components/atomics"
import Page from "../../../components/Pages/Page"
import colors from "../../../colors"
import { useState } from "react"
import { setParams } from "../../../utils/query"
import { formatToMoney } from "../../../utils/cupom"
import { useParams } from "../../../hooks"
const benefits = [
  {
    title: "💻 Acesso à Versão Web",
    description: "Gerencie suas tarefas, hábitos e metas pelo seu notebook ou computador, com a mesma facilidade do aplicativo.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/web01-pt.png",
    },
  },
  {
    title: "🌐 Extensão de Navegador",
    description:
      "Visualize e conclua facilmente todas as suas tarefas, hábitos e metas do dia, enquanto estiver usando o seu navegador (Chrome ou Firefox).",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/extension01-pt.png",
    },
  },
  {
    title: "📲 Widgets",
    description:
      "Visualize e conclua suas tarefas, hábitos e metas do dia sem abrir o aplicativo, direto pela tela inicial do seu celular.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/widget-pt.png",
    },
  },
  {
    title: "🗓️ +5 Modos de Visualização Personalizados",
    description:
      "Tenha acesso a 5 modos extras de visualizar suas atividades: Agenda (3 dias, 7 dias, Mensal), Linha do Tempo e Pendências.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/modeView01-pt.png",
    },
  },
  {
    title: "🚫 Sem Anúncios",
    description: "Concentre-se em suas tarefas, hábitos e metas, sem interrupções.",
  },
  {
    title: "∞ Uso Ilimitado",
    description:
      "Adicione quantas tarefas, metas e hábitos quiser, sem restrições.",
    img: {
      uri:
        "https://flynow.s3.amazonaws.com/premium/limitActivities01-pt.png",
    },
  },
  {
    title: "🔔 Notificações Personalizadas",
    description:
      "Escolha sons diferentes para suas notificações, dando um toque pessoal aos seus lembretes.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/customNotification01-pt.png",
    },
  },
  {
    title: "✔️ Conclua Tarefas pela Notificação",
    description:
      "Marque atividades como concluídas direto da notificação, economizando seu tempo.",
    img: {
      uri:
        "https://flynow.s3.amazonaws.com/premium/notificationDone-pt-light.png",
    },
  },
  {
    title: "📊 Relatórios Semanais e Mensais",
    description:
      "Receba Relatórios Semanais e Mensais detalhados sobre o seu desempenho, entendendo onde melhorar e comemorando suas conquistas.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/reportWeekly01-pt.png",
    },
  },
  {
    title: "📝 Notas em Atividades",
    description:
      "Adicione anotações específicas para suas tarefas, hábitos e metas que te ajudem a lembrar de detalhes importantes.",
    img: { uri: "https://flynow.s3.amazonaws.com/premium/note01-pt.png" },
  },
  {
    title: "⌛ Temporizador & Cronômetro",
    description: "Utilize temporizadores e cronômetros para suas tarefas, hábitos e metas que te ajudam a manter o foco e aumentar sua produtividade.",
    img: { uri: "https://flynow.s3.amazonaws.com/premium/timer01-pt.png" },
  },
  {
    title: "💜 Roda da Vida!",
    description:
      "Classifique suas tarefas, hábitos e metas em categorias e visualize o gráfico de sua Roda da Vida, entendendo onde dedicar mais energia para alcançar bem-estar e progresso constante.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/categories01-pt.png",
    },
  },
  {
    title: "📅 Integração com o Calendário",
    description:
      "Sincronize eventos automaticamente do Google Calendar para o Flynow Produtividade e vice-versa e tenha todos os seus compromissos em ambos os lugares a fim de que nada passe despercebido.",
    img: {
      uri: "https://flynow.s3.amazonaws.com/premium/syncCalendar01-pt.png",
    },
  },
  {
    title: "📢 Múltiplas Notificações",
    description:
      "Programe lembretes adicionais para suas tarefas e hábitos, garantindo que você esteja sempre no controle e não se esqueça de nada.",
    img: {
      uri:
        "https://flynow.s3.amazonaws.com/premium/multipleNotifications01-pt.png",
    },
  },
  // {
  //   title: "Check-List dentro de Tarefas!",
  //   description:
  //     "Crie check-list dentro de suas tarefas e tenha mais controle sobre elas!",
  //   img: {
  //     uri: "https://flynow.s3.amazonaws.com/premium/taskcheckList-pt-light.png",
  //   },
  // },
  {
    title: "💡 E muito mais...",
    description:
      "Tenha acesso a todas as futuras atualizações e novidades do aplicativo em primeira mão.",
  },
  // {
  //   title: 'Garantia de 7 dias!',
  //   img: {
  //     uri: 'https://flynow.s3.amazonaws.com/premium/garantia.png',
  //   },
  //   description:
  //     'Se, por qualquer motivo, você não gostar da versão premium, te devolvemos todo o seu dinheiro!',
  // }
]

const plans = [
  {
    label: "12 MESES",
    price: 79.99,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo para pagar usando o Cartão de Crédito, Apple Pay ou Google Pay. No campo e-mail, informe o seu e-mail cadastrado no aplicativo, para a liberação do acesso Premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url: "https://buy.stripe.com/3csbKveWJ7y58Fi144?locale=pt-BR",
      },
      {
        key: "PIX",
        description:
          "Clique no link abaixo para pagar com o PIX. Você pode pagar de 3 formas: Ler o QR Code, Copiar o código do QR Code ou com a Chave Pix.",
        titleButton: "Pagar com PIX",
        url: "https://nubank.com.br/pagar/uwbk/9I1AnxVyy9",
        FLYN20: "https://nubank.com.br/cobrar/uwbk/668ed33c-48c9-421b-8a4a-896e42accba5"
      },
    ],
  },
  // {
  //   label: "36 MESES",
  //   price: 159.99,
  //   data: [
  //     {
  //       key: "CARTÃO, MERCADO PAGO OU PAYPAL",
  //       description: 'Clique no link abaixo, para pagar com o Mercado Pago. Pelo mercado pago, você pode pagar com o seu saldo do mercado pago, paypal, pix ou cartão.',
  //       titleButton: 'Pagar com Mercado Pago',
  //       url: "https://mpago.la/1j6eR2P",
  //     },
  //     {
  //       key: "PIX",
  //       description: 'Clique no link abaixo, copie o código do QR Code, abra o app do seu banco, busque a opção Pix Copie e Cola e cole o código copiado para pagar.',
  //       titleButton: 'Pagar com PIX',
  //       url: "https://nubank.com.br/pagar/uwbk/gzZRy9Ni46",
  //     },
  //     {
  //       key: "TRANSFERÊNCIA BANCÁRIA",
  //       description: 'Agência: 0001 | Conta: 1441568-2 | Banco: Nubank | Titular: Rogerd | Valor: 159.99',
  //     },
  //   ],
  // },
  {
    label: "VITALÍCIO",
    price: 199.90,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo para pagar usando o Cartão de Crédito, Apple Pay ou Google Pay. No campo e-mail, informe o seu e-mail cadastrado no aplicativo, para a liberação do acesso Premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url: "https://buy.stripe.com/cN29CncOBdWt08M7sJ?locale=pt-BR",
      },
      {
        key: "PIX",
        description:
          "Clique no link abaixo para pagar com o PIX. Você pode pagar de 3 formas: Ler o QR Code, Copiar o código do QR Code ou com a Chave Pix.",
        titleButton: "Pagar com PIX",
        url: "https://nubank.com.br/cobrar/uwbk/66a24111-5dfb-472c-a8f5-f21f1d576915",
        FLYN20: "https://nubank.com.br/cobrar/uwbk/66a2407a-2637-4305-a547-b0916652cca1"
      },
    ],
  },
]

function PremiumPromotion() {
  const [plan, setPlan] = useState(plans[0])
  const [pay, setPay] = useState(plans[0].data[0])
  const {params} = useParams()


  const getUrlCheckout = url => {
    if(params.cupom){
      if (url.includes("stripe")) {
        return setParams(url, {
          prefilled_promo_code: params.cupom,
          utm_source: params.utm_source,
          utm_medium: params.utm_medium,
          utm_campaign: params.utm_campaign,
          prefilled_email: params.email,
          client_reference_id: params.user_id,
        })
      }
      if (url.includes("nubank")) {
        if(pay[params.cupom]) return pay[params.cupom]
        return null
      }
    }
    return url
  }

  function getPrices(basePrice) {
    const discontPercentage = params.cupom
      ? Number(params.cupom.replace(/\D/g, "") || "0")
      : 0
    const price = basePrice
    return {
      price: formatToMoney(price),
      newPrice: params.cupom
        ? formatToMoney(price * (1 - discontPercentage / 100))
        : formatToMoney(price),
      discontPercentage,
    }
  }
  function getInfo() {
    const discontPercentage = params.cupom
      ? Number(params.cupom.replace(/\D/g, "") || "0")
      : 0
    return {
      URL_CHECKOUT: getUrlCheckout(pay.url),
      textCupom: params.cupom
        ? discontPercentage
          ? `${discontPercentage}% DE DESCONTO! APROVEITE! 🔥`
          : null
        : null,
      discontPercentage,
      ...getPrices(plan.price),
    }
  }

  const {
    URL_CHECKOUT,
    textCupom,
    discontPercentage,
  } = getInfo()

  return (
    <Page
      title="Premium"
      titlePage="Premium"
      pageId="premium"
      description={
        "Torne-se Premium e tenha acesso a ferramentas exclusivas! Com o plano premium, você tem acesso a diversas ferramentas para te auxiliar a ser mais produtivo, manter seus hábitos e atingir seus objetivos!"
      }
      forceTheme={'default'}
    >
      <Main>
        <div>
          <h1>Torne-se Premium e tenha acesso a ferramentas exclusivas!</h1>

          <p style={{color:'#555'}}>
            Que tal dar o próximo passo na sua jornada de produtividade? Com a <b>Assinatura Premium</b>, você tem acesso a <b>ferramentas exclusivas</b> e <b>recursos avançados</b> para ser mais produtivo, criar bons hábitos e alcançar seus objetivos! 🚀
          </p>

          {textCupom && (
            <h4 style={{ color: "red", textAlign: "center", marginBottom: 20 }}>
              {textCupom}
            </h4>
          )}

          <br/>
          <h2>Por que investir no Premium?</h2>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {benefits.map(i => (
              <Benefit>
                <h3 style={{textAlign: 'center', margin:0, color: '#444'}}>
                  {i.title}
                </h3>
                <p style={{marginTop: 5, color: '#666'}}>
                  {i.description}
                </p>
                {i.img && <img src={i.img.uri} height="150" />}
              </Benefit>
            ))}
          </div>

            <br />
            <h4>
            🚨Informações importantes para adquirir a Assinatura Premium
            </h4>
          <p>
            1️⃣ Escolha o <b>plano que deseja assinar</b> e o <b>método de pagamento</b>. <br />
            2️⃣ <b>Realize o pagamento</b> conforme as instruções para o pagamento do método escolhido.<br />
            3️⃣ Informe o seu <b>email cadastrado no app</b>! Se tiver qualquer dúvida, <b>entre em contato com a gente!</b>
          </p>

          <br />
          <h3>Selecione o plano:</h3>
          <Row>
            {plans.map(i => (
              <div
                onClick={() => {
                  setPlan(i)
                  setPay(i.data[0])
                }}
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: 10,
                  marginRight: 10,
                  marginTop: 5,
                  border: `1px solid ${i.label === plan.label ? colors.primary : '#666'}`,
                  backgroundColor:
                    i.label === plan.label
                      ? `${colors.primary}`
                      : "#fff",
                  color: i.label === plan.label ? colors.white : '#666',
                }}
              >
                <h4>{i.label}</h4>
                {discontPercentage ? (
                  <s>
                    <h3
                      style={{
                        fontSize: discontPercentage ? 20 : 28,
                        textAlign: "center",
                      }}
                    >
                      {i.price}
                    </h3>
                  </s>
                ) : (
                  <h3>{i.price}</h3>
                )}
                {discontPercentage >0 && (
                  <h3 style={{ fontSize: 28 }}>
                    {getPrices(i.price).newPrice}
                  </h3>
                )}
              </div>
            ))}
          </Row>
          <h3 style={{ marginTop: 15 }}>Pagar com:</h3>
            <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
          {plan.data.map(i => (

                  <div
                    style={{
                      padding: "15px 30px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                      width: '90%',
                      marginTop: 10
                    }}
                    onClick={()=> setPay(i)}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                        textAlign: "center",
                        width: '100%'
                      }}
                      href={URL_CHECKOUT}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i.key}
                    </a>
                  </div>
          ))}
          </div>
          <h4 style={{ marginTop: 15 }}>🚨 Instruções para o pagamento:</h4>
          <p>
           Informe o seu e-mail cadastrado no aplicativo, para a liberação do acesso Premium. No campo E-MAIL ou descrição do PIX.
          </p>

          <>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
               Qualquer dúvida, entre em contato:
              </h4>
              <Text center style={{ fontSize: "0.95rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={
                        "https://api.whatsapp.com/send/?phone=5531999107753"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WhatsApp
                    </a>
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={"mailto:productivity@appflynow.com"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email
                    </a>
                  </div>
                  <Text style={{ fontSize: "0.9rem" }}>
                    productivity@appflynow.com
                  </Text>
                </div>
              </Text>
            </>

        </div>
      </Main>
    </Page>
  )
}

export default PremiumPromotion
