import React from "react"
import Layout from "../../layout"
import SEO from "../../seo"

// import { Container } from './styles';

function Page({
  children,
  title,
  description,
  pageId,
  url,
  imageUrl,
  logo,
  titlePage,
  hideHeader,
  hideFooter,
  forceTheme,
  primaryColor
}) {
  return (
    <Layout
      title={title?.toUpperCase()}
      logo={logo}
      hideHeader={hideHeader}
      hideFooter={hideFooter}
      forceTheme={forceTheme}
      primaryColor={primaryColor}
    >
      <SEO
        title={titlePage ?? title}
        description={description}
        pageId={pageId}
        url={url}
        imageUrl={imageUrl}
      />
      {children}
    </Layout>
  )
}

export default Page
